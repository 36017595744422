import React from 'react';
import PropTypes from 'prop-types';

import Layout from '../../components/layout';
import Menu from '../../components/menuComponent';
import Article from '../../components/articleComponent';

import Articles from '../../data/articles';

const ArticleContainer = ({ location }) => {
  const { pathname } = location;
  const filter = pathname.split('/').filter((pathString) => pathString.length > 0).pop();
  const article = Articles.find((data) => data.link === filter);

  return (
    <Layout
      title="Latest trends in colour | Wow Interior Design"
      description="Discussing the latest colour trends & how neutral colours can be used to make a big an impact to a room."
    >
      <Menu />
      <Article
        article={article}
      />
    </Layout>
  );
};

ArticleContainer.propTypes = {
  location: PropTypes.object.isRequired,
};

export default ArticleContainer;
